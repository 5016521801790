.main_container {
    background-color: #9dbdd2;
    height: 100%;
    padding: 10px 0 20px;
}

.main_sec_container {
    background-color: #fff;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding: 40px;
    margin-top: 10px;
    max-width: 760px !important;
    padding: 0 80px !important;
}

.hideButton {
    display: none;
}

@media (max-width: 600px) {
    .main_sec_container {
        padding: 20px 5px !important;
    }
}