.content-dashboard {
    padding: 2rem;
    font-family: sans-serif;
  }
  
  .content-dashboard h1 {
    margin-bottom: 2rem;
  }
  
  .content-dashboard table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 3rem;
  }
  
  .content-dashboard th,
  .content-dashboard td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .content-dashboard th {
    background-color: #f2f2f2;
  }
  
  .hidden {
    display: none;
  }
  
  .column-toggles {
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    font-size: 0.9rem;
  }
  
  .column-toggles label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  /*-- OtherBlogs --*/

  h2 {
    margin-top: 2rem;
  }
  
  section table {
    border: 1px solid #ccc;
    margin-bottom: 2rem;
  }
  
  section h2 {
    color: #1a1a1a;
  }
  
  section h2:nth-of-type(4) {
    color: #228B22; /* green for active */
  }
  
  section h2:nth-of-type(5) {
    color: #B22222; /* red for inactive */
  }

    /*-- Filters like sheets --*/
  .filterable-header {
    position: relative;
  }
  .filterable-header input {
    font-size: 0.85em;
    padding: 4px;
  }

  .table-filter {
    width: 95%;
    margin-top: 4px;
    padding: 4px;
    font-size: 12px;
    box-sizing: border-box;
  }
  
  /* Add to ContentDashboard.css */
.dropdown-filter {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 6px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    min-width: 120px;
    z-index: 10;
  }
  
  .dropdown-filter div {
    padding: 4px;
    cursor: pointer;
  }
  
  .dropdown-filter div.active {
    background-color: #eef;
  }
  
  /* Consistent dropdown toggle style (used for ▼ icons) */
  .dropdown-toggle {
    margin-left: 4px;
    font-size: 0.8em;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    user-select: none;
  }
  
  .dropdown-toggle:hover {
    text-decoration: underline dotted;
  }