.intro_sec {
    position: relative;
    padding: 0px 14px;
}

.author_image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0px 10px 0px 0px;
    max-width: 100%;
    overflow: hidden;
}

.author_image img {
    border-radius: 50%;
    margin-right: 20px;
    max-width: 120%;
    /* display: none; */
    object-fit: cover;
    object-position: 55%;
    transform: scale(1.2) translate(-4%, -5%);
}

.intro_image img {
    max-width: 100%;
    width: 100%;
}

.intro_details {
    display: flex;
    align-items: center;
    margin: 0px auto;
    text-align: center;
    justify-content: center;
}

.aut_d {
    align-self: center;
    color: #000;
    font-weight: 600;
    font-size: 14px !important;
}

.aut_d span {
    font-weight: 600;
    color: #000;
}

.date{
    font-weight: 400 !important;
    color: #999 !important;
}

span.views {
    color: #ff8900;
    font-weight: 400;
}

.intro_text p {
    margin: 0px auto 0px auto;
    text-align: left;
    line-height: 1.6em;
    margin-bottom: 20px;
    font-size: 1.2em;
}

@media (max-width: 600px) {
    .intro_text p {
        margin: 20px auto;
        text-align: left;
        line-height: 1.6em;
        font-size: 1.2em;
    }
    .intro_details {
        margin: 10px auto;
    }
    .intro_details p {
        margin: 5px auto;
    }

    .intro_image img {
        max-width: 100%;
        width: 100%;
    }
}