.conclusion-section {
    padding: 60px 10px;
    font-size: 1.0em;
    line-height: 1.6;
      margin: 20px auto;
}
  
  .conclusion-copy strong {
    font-size: 1.25rem;
    display: block;
    margin-bottom: 10px;
  }
  