header {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    margin-top: 0;
}

.sec_container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.top_small_txt {
    font-size: 1.15rem;
    color: #999;
    text-align: center;
    margin: 0;
}

.logo {
    padding: 0px 0;
    width: 25%;
    position: relative;
    /* font-size: 40px;
    font-weight: 900; */
}

.logo img {
    max-width: 100%;
}

@media (max-width: 600px) {
    .logo {
        margin-left: 0;
        max-width: 140px;
        width: 40%;
    }
}