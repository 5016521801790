.other-blogs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .blog-item {
    width: calc(33.3333% - 10px);
    margin-bottom: 20px;
    box-sizing: border-box;
    border: 5px solid #fdfcfc;
  }
  
  .blog-item img {
    width: 100%;
    height: auto;
    max-height: 170px;
    min-height: 170px;
    object-fit: cover;
  }
  
  .blog-item p {
    text-align: left;
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif;
    line-height: 1.1rem;
    margin: 15px auto;
    margin-top: 0px;
    margin-left: 0px;
  }

  .blog-item a {
    text-decoration: none !important;
  }

  .blog-item a:hover {
    text-decoration: underline !important;
  }
  
  .blog-item .category {
      font-size: 12px !important;
      font-weight: 600;
      color: #837979;
      text-align: left;
      margin-top: -10px;
  }

  .blog-item a:hover {
    background-color: #0099bd;
    box-shadow: 0 1px 10px rgba(223, 250, 127, 0.15), 0 5px 5px rgba(241, 255, 113, 0.12);
}

  @media (max-width: 768px) {
    .blog-item {
      width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 480px) {
    .blog-item {
      width: 49%;
      float: left;
      box-sizing: border-box;
    }

    .blog-item img {
      max-height: 160px;
    }

    .blog-item + .blog-item {
      gap: 2px;
    }

    .blog-item .category {
      display: none;
    }
    .blog-item p {
      font-size: .9em!important;
      line-height: 1.1rem;
    }
    .blog-item .category {
      display: none;
    }
  }
  