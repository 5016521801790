.sub-footer {
    background-color: #fff;
    margin-top: 30px;
}

.subf_container p {
    font-size: 26px;
    font-weight: 600 !important;
    margin-left: 0px;
    padding-top: 0px;
}