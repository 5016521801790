.main_container {
    background-color: #9dbdd2;
    height: 100%;
    padding: 10px 0 20px;
}

.main_sec_container {
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    height: auto;
}

.hideButton {
    display: none;
}

@media (max-width: 600px) {
    .main_sec_container {
        padding: 20px 5px !important;
    }
}