@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:wght@300;400;500;700&display=swap');



h1 {
    font-size: 2rem;
    font-weight: 500 !important;
    line-height: 1.1;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 25px;
    font-family: 'Atlassian Sans';
    /* font-family: "Roboto", Arial, Helvetica, sans-serif; */
    /* font-family: 'Playfair Display', serif; */
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

p {
    font-size: 1.2em;
    margin: 10px auto;
    text-align: left;
    line-height: 1.55em;
    letter-spacing: normal;
    color: #2c2c2c;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
}

li {
    padding: 5px 0px;
}

    /* Custom All Version CSS Override */
.main_sec_container {
    max-width: 680px;
}

span.big {
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 0px;
    display: block;
}

span.location {
    font-size: .8em;
}

span.highlight {
    background: #ffd449;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 5px;
    font-size: 24px;
    font-weight: 700;
    padding: 3px 15px;
}

span.blue {
    background: #d7f7ff;
    border: 2px solid #fff3b7;
    color: #626262;
    font-size: 1.0em;
    font-weight: 400;
    line-height: 1.6em;
    padding: 25px 20px;
    display: block;
}

span.Yellow {
    background: #ffffe6;
    border: 1px solid #ecca2c;
    font-size: 1.0em;
    font-weight: 400;
    line-height: 1.6em;
    padding: 25px 20px;
    display: block;
}

span#container {
    display: block;
    margin: -50px 0 0;
    min-width: 240px;
    width: 50%;
}

span.special {
    border: 2px solid #ededed;
    padding: .5em 2em;
    font-size: .9em;
    border-radius: 2px;
    background: #f6f6f6;
    display: block;
    margin: 10px 0;
    font-weight: 500;
    font-family: Roboto,Arial,Helvetica,sans-serif;
}

span.extras {
    display: block;
    font-size: .8em;
    color: #fb0000;
    font-weight: 500;
}

span.discount {
    font-size: 1.2em;
    color: #038e1b;
    font-weight: 500;
    display: block;
}

span.fivestar {
    color: #ffc767;
    overflow: hidden;
}

span.fivestar-copy {
    font-size: .8em;
}

span.strikeblack {
    text-decoration: line-through;
    color: #898989;
    font-weight: 400;
    font-size: .8em;
}

span.link {
    display: table;
    font-weight: 300;
    color: #1c80e5;
    /* border: 1px solid #7db7ff; */
    /* padding: .2em .5em .1em .5em; */
}

a.text-link {
    font-weight: 300;
    text-decoration: underline;
}

.link:hover {
    /* border: 1px solid #cacaca; */
    /* background: #f4f4f4; */
}

a.text-link:hover {
    color: #00a464;
}
        aside:before, aside:after {
            height: 0;
            width: 0;
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 0;
        } 

        aside {
            margin: 60px 0 10px 0;
            line-height: 34px;
            position: relative;
            &.ribbon3 {
            background-color: #9bc4ff;
            line-height: 1.8em;
            color: #fff;
            font-size: 1.0em;
            text-align: center;
                &:after, &:before {
                    border-width: 15px 0px 19px 24px;
                }
                &:before {
                    border-color: #ffffff transparent;
                    left: -23px;
                    /*top: 3px;*/
                }
                &:after {
                    border-color: transparent #9bc4ff;
                    /*right: -23px;*/
                }
            }
        }
  
.intro_details {
    justify-content: left !important;
}

br {
    display: block;
    content: "";
    line-height: 1.2em;
    margin: 15px;
}

button {
    width: 100%;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    display: block;
    border: none;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
}

.main_container {
    background-color: #f9fbfa;
}

.offer_image img {
    margin: 10px auto;
}

.css-3bjak1 {
    display: none !important;
}


@media (max-width: 600px) {
    p {
        font-size: 1.2em;
        margin: 10px auto 5px auto;
        text-align: left;
        font-weight: 400;
        line-height: 1.6em;
        letter-spacing: normal;
        color: #3a3838;
    }

    h1 {
        font-size: 2.0rem !important;
    }

    span.highlight {
        font-size: 24px;
        padding: 15px 25px;
        display: block;
    }

    span.big {
        padding: 10px 0px;
        display: block;
    }

    .offer_post h2, h2 {
        line-height: 30px !important;
        font-size: 25px !important;
        text-transform: capitalize !important;
    }

    .button {
        padding: 14px 15px !important;
    }
}



